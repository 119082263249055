<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-organization-list' }">Organizations</router-link> &gt;
                    <router-link :to="{ name: 'organization-dashboard', params: { organizationId: this.$route.params.organizationId } }">{{ organizationName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card tile elevation="4" class="pa-0 mt-0">
                        <v-toolbar dense flat color="blue darken-2" dark>
                            <v-toolbar-title>BrandProfile</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-card-text>
                            <p class="mb-0 mt-2">
                                A BrandProfile is a set of assets that represent your brand or ways of communicating with your company. These assets include logos and other images, color palettes, verified domains, email and postal addresses, and links to your website and social media accounts.
                            </p>
                            <p class="mb-0 mt-2">
                                You can manage all your BrandProfiles at <a href="https://brandprofile.org" target="_blank">brandprofile.org</a>.
                            </p>

                            <!-- <p class="text-overline mb-0 mt-8">BrandProfile</p> -->

                            <template v-if="brandprofile">
                            <p class="mb-0 pb-0 mt-6">
                                {{ brandprofile }}
                                <v-btn icon color="blue darken-2" @click="dialogEditBrandProfile = true">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p>
                            <!-- <p class="mb-0 pb-0">
                                <TextLink :href="viewBrandProfileURL" target="_blank">{{ viewBrandProfileURL }}</TextLink>
                            </p> -->
                            </template>

                            <template v-if="!brandprofile">
                            <p class="mb-0 pb-0 mt-6">
                                Connect your organization to a BrandProfile.
                            </p>
                            <p class="mb-0 pb-0">
                                <v-btn icon color="blue darken-2" @click="dialogEditBrandProfile = true">
                                    <font-awesome-icon :icon="['fas', 'pencil-alt']"/>
                                </v-btn>
                            </p>
                            </template>
                        </v-card-text>
                    </v-card>
                    <v-dialog v-model="dialogEditBrandProfile" max-width="600">
                        <v-card tile elevation="4" class="pa-0" max-width="600">
                            <v-toolbar dense flat color="white">
                                <v-toolbar-title class="blue--text text--darken-2">Connect BrandProfile</v-toolbar-title>
                            </v-toolbar>
                            <!-- <v-divider class="mx-5 mb-10"></v-divider> -->
                            <v-card-text>
                                <p>Changes to the logo, color palette, and brand name defined in the BrandProfile will be automatically reflected in all your customer service website.</p>
                            </v-card-text>
                            <v-form @submit.prevent="editBrandProfileAlias" @keyup.enter.native="editBrandProfileAlias" class="px-5 mt-5">
                                <v-text-field
                                    ref="editableBrandProfileInput"
                                    v-model="editableBrandProfileAlias"
                                    label="BrandProfile Alias"
                                    outlined
                                    dense
                                ></v-text-field>
                            </v-form>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn elevation="4" class="blue darken-2 white--text" @click="editBrandProfileAlias" :disabled="!isEditBrandProfileAliasFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditBrandProfile = false">Cancel</v-btn>
                                <v-spacer/>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        // TextLink,
    },
    data: () => ({
        organization: null,
        brandprofile: null,
        error: null,

        dialogEditBrandProfile: false,
        editableBrandProfileAlias: null,
        submitFormTimestamp: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        organizationName() {
            return this.organization?.name ?? 'Unknown';
        },
        isViewReady() {
            return this.organization !== null;
        },
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isEditBrandProfileAliasFormComplete() {
            return this.editableBrandProfileAlias;
        },
    },
    watch: {
        dialogEditBrandProfile(newValue) {
            if (newValue) {
                this.editableBrandProfileAlias = this.brandprofile;
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('editableBrandProfileInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadOrganization() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadOrganization: true });
                const response = await this.$client.organization(this.$route.params.organizationId).currentOrganization.get();
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.organization = response;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadOrganization: false });
            }
        },
        async loadSetting() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadSetting: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.get({ name: 'brandprofile' });
                console.log(`organization/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.brandprofile = response.content;
                } else {
                    // TODO: redirect back to organization list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load organization', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadSetting: false });
            }
        },
        async editBrandProfileAlias() {
            try {
                if (Number.isInteger(this.submitFormTimestamp) && this.submitFormTimestamp + 500 > Date.now()) {
                    return;
                }
                this.submitFormTimestamp = Date.now();
                this.error = false;
                this.$store.commit('loading', { editBrandProfileAlias: true });
                const response = await this.$client.organization(this.$route.params.organizationId).setting.edit({ name: 'brandprofile' }, { content: this.editableBrandProfileAlias });
                console.log(`editBrandProfileAlias: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.dialogEditBrandProfile = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.loadSetting();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
                }
            } catch (err) {
                console.error('failed to edit BrandProfile', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to edit BrandProfile' });
            } finally {
                this.$store.commit('loading', { editBrandProfileAlias: false });
            }
        },
    },
    mounted() {
        this.loadOrganization();
        this.loadSetting();
    },
};
</script>
